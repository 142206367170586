import { GameState } from "store/gameSlice";
import { Player } from "store/playerSlice";

export enum TransitionType {
  FlyOutOfDeck,
  FlyOutOfPile,
  FlyInToPile,
}

export type Card = {
  type: CardType;
  suit: Suit;
  value: CardValue;
  deck: number;
  isGrouped: boolean;
  createdTimeMs?: number;
  score?: number;
  id: string;
  needsTransition?: TransitionType;
};

export enum CardType {
  INVALID,

  TWO_OF_DIAMONDS,
  THREE_OF_DIAMONDS,
  FOUR_OF_DIAMONDS,
  FIVE_OF_DIAMONDS,
  SIX_OF_DIAMONDS,
  SEVEN_OF_DIAMONDS,
  EIGHT_OF_DIAMONDS,
  NINE_OF_DIAMONDS,
  TEN_OF_DIAMONDS,
  JACK_OF_DIAMONDS,
  QUEEN_OF_DIAMONDS,
  KING_OF_DIAMONDS,
  ACE_OF_DIAMONDS,

  TWO_OF_HEARTS,
  THREE_OF_HEARTS,
  FOUR_OF_HEARTS,
  FIVE_OF_HEARTS,
  SIX_OF_HEARTS,
  SEVEN_OF_HEARTS,
  EIGHT_OF_HEARTS,
  NINE_OF_HEARTS,
  TEN_OF_HEARTS,
  JACK_OF_HEARTS,
  QUEEN_OF_HEARTS,
  KING_OF_HEARTS,
  ACE_OF_HEARTS,

  TWO_OF_CLUBS,
  THREE_OF_CLUBS,
  FOUR_OF_CLUBS,
  FIVE_OF_CLUBS,
  SIX_OF_CLUBS,
  SEVEN_OF_CLUBS,
  EIGHT_OF_CLUBS,
  NINE_OF_CLUBS,
  TEN_OF_CLUBS,
  JACK_OF_CLUBS,
  QUEEN_OF_CLUBS,
  KING_OF_CLUBS,
  ACE_OF_CLUBS,

  TWO_OF_SPADES,
  THREE_OF_SPADES,
  FOUR_OF_SPADES,
  FIVE_OF_SPADES,
  SIX_OF_SPADES,
  SEVEN_OF_SPADES,
  EIGHT_OF_SPADES,
  NINE_OF_SPADES,
  TEN_OF_SPADES,
  JACK_OF_SPADES,
  QUEEN_OF_SPADES,
  KING_OF_SPADES,
  ACE_OF_SPADES,

  JOKER_A,
  JOKER_B,

  SPINNER,
  CARD_BACK,
}

export enum Suit {
  INVALID,
  CLUBS,
  DIAMONDS,
  HEARTS,
  SPADES,
  SUITLESS,
}

export enum CardValue {
  INVALID,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  TEN,
  JACK,
  QUEEN,
  KING,
  ACE,
  JOKER,
}

export type GameStateForPlayer = {
  hand: Card[];
  deckSize: number;
  pile: Card[];
  players: Player[];
  turn: number;
  round: number;
  state: GameState;
};

const getSuit = (card: CardType): Suit => {
  if (card > 0) {
    return Math.floor((card - 1) / 13 + 1);
  } else {
    return Suit.INVALID;
  }
};

const getValue = (card: CardType): CardValue => {
  if (card > 0 && card < 53) {
    return ((card - 1) % 13) + 1;
  } else if (card === 53 || card === 54) {
    return CardValue.JOKER;
  } else {
    return CardValue.INVALID;
  }
};

const getScore = (value: CardValue) => {
  switch (value) {
    case CardValue.TWO:
    case CardValue.THREE:
    case CardValue.FOUR:
    case CardValue.FIVE:
    case CardValue.SIX:
    case CardValue.SEVEN:
    case CardValue.EIGHT:
    case CardValue.NINE:
      return value + 1;
    case CardValue.TEN:
    case CardValue.JACK:
    case CardValue.QUEEN:
    case CardValue.KING:
      return 10;
    case CardValue.ACE:
      return 20;
    case CardValue.JOKER:
      return 50;
    default:
      return 0;
  }
};

export type SchemaCard = {
  type: CardType;
  deck: number;
};

export const parseCard = (card: SchemaCard): Card => {
  return {
    type: card.type,
    deck: card.deck,
    suit: getSuit(card.type),
    value: getValue(card.type),
    score: getScore(getValue(card.type)),
    isGrouped: false,
    id: `${card.type}-${card.deck}`,
  };
};
