export const API_URL = "https://aces-backend.azurewebsites.net";
export const cardWidth = 105;
export const cardHeight = 148;

export const red =
  " text-red-600 fill-red-600 stroke-red-600 dark:text-red-500 dark:fill-red-500 dark:stroke-red-500";
export const black =
  " text-gray-900 fill-gray-900 stroke-gray-900 dark:text-gray-200 dark:fill-gray-200 dark:stroke-gray-200 ";

export const borderedRed = " border-red-600 dark:border-red-500 " + red;
export const borderedBlack = " border-gray-900 dark:border-gray-200 " + black;
